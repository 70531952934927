import axios from "axios";
import keycloakService from './auth.keycloak';

const api = axios.create({
    baseURL: window.location.href.includes('hml')
        ? 'https://hiae-api.hml.hoobox.one'
        : process.env.REACT_APP_API_URL || 'https://hiae-api.hoobox.one',
    headers: {},
});

api.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${keycloakService.getToken() ?? null}`;
    return config;
});

const makeHttpRequest = (apiCall) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiCall();
            resolve(response.data);
        } catch (e) {
            // const {
                // showPopUp,
                // ...swalObj
            // } = httpErrorHandler(e);

            // if (showPopUp) {
                // Swal.fire(swalObj);
            // }

            reject(e);
        }
    });
}

export const getRequest = (path, options = {}) =>
    makeHttpRequest(() => api.get(path, options));

export const postRequest = (path, data, options = {}) =>
    makeHttpRequest(() => api.post(path, data, options));

export const putRequest = (path, data, options = {}) =>
    makeHttpRequest(() => api.put(path, data, options));
    
export const deleteRequest = (path, options = {}) =>
    makeHttpRequest(() => api.delete(path, options));

export { api };