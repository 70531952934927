import Keycloak from 'keycloak-js';

const _kc = new Keycloak({
    'realm': window.location.href.includes('hml') 
      ? 'hoobox'
      : process.env.REACT_APP_KEYCLOAK_REALM || 'HIAE',
    'url': window.location.href.includes('hml')
    ? 'https://auth.hml.hoobox.one/'
    : process.env.REACT_APP_KEYCLOAK_AUTH_URL || 'https://auth.hoobox.one/',
    'ssl-required': 'external',
    'clientId': 'portalhiae-frontend',
    'public-client': true,
    'confidential-port': 0,
    'onLoad': 'login-required'
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'login-required',
    checkLoginIframe: false,
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = () => _kc.login({
    idpHint: process.env.REACT_APP_KEYCLOAK_IDP_HINT || 'oidc',
    redirectUri: window.location.href.includes("hml")
    ? "https://auth.hml.hoobox.one"
    : process.env.REACT_APP_KEYCLOAK_REDIRECT_URI ||
      "https://auth.hoobox.one/",
});

const doLogout = () => _kc.logout();

_kc.onTokenExpired = () => {
  console.log('token expired');
  _kc.updateToken(30).then(() => {
      console.log('successfully get a new token');
  }).catch(err => {
    doLogout();
    console.log(err)
  });
}

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const resolveGivenName = () => {
    let given_name = _kc.tokenParsed?.name || _kc.tokenParsed?.preferred_username

    return given_name?.split(' ')[0] || null
};

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getTokenParsed,
  resolveGivenName,
};

export default UserService;
